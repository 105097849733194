<template>
    <div class="backup">
        <div class="backup__logo">
            <router-link to="/">
                <img src="../assets/kompreLogo-min.png" alt="Komputery poleasingowe, tanie laptopy oraz monitory powystawowe.">
            </router-link>
        </div>
        <div class="backup__content" v-if="loaded">
            <div class="backup__content__id">
                <div class="backup__content__id__header">Backup usuniętego produktu</div>
                <div class="backup__content__id__section">
                    <div class="backup__content__id__section__snippet"><i class="fi fi-rr-duplicate"></i><span>ID:{{ this.$route.params.id }}</span><i class="fi fi-rr-box-alt"></i><span>{{ backup.product.name }}</span></div>
                </div>
            </div>  
            <div class="backup__content__id__section">
                <div class="backup__content__id__section">
                   <div class="backup__content__id__section__product">Nazwa produktu</div>
                    <span>{{ backup.product.name }}</span>
                </div>
                <div class="backup__content__id__section">
                   <div class="backup__content__id__section__product">Informacje w kafelku</div>
                    <span>{{ backup.product.snippet }}</span>
                </div>
                <div class="backup__content__id__section">
                   <div class="backup__content__id__section__product">Producent</div>
                    <span>{{ backup.product.manufacturer }}</span>
                </div>
                <div class="backup__content__id__section">
                   <div class="backup__content__id__section__product">Model</div>
                    <span>{{ backup.product.model }}</span>
                </div>
                <div class="backup__content__id__section">
                   <div class="backup__content__id__section__product">EAN</div>
                    <span>{{backup.product.ean }}</span>
                </div>
                <div class="backup__content__id__section">
                   <div class="backup__content__id__section__product">SKU</div>
                    <span>{{backup.product.sku }}</span>
                </div>
                <div class="backup__content__id__section">
                   <div class="backup__content__id__section__product">Kod wewnętrzny</div>
                    <span>{{ backup.product.innerCode }}</span>
                </div>
                <div class="backup__content__id__section">
                    <div class="backup__content__id__section__product">Położenie - Regał / Półka</div>
                    <span>{{ backup.product.location }}</span>
                </div>
                <div class="backup__content__id__section">
                    <div class="backup__content__id__section__product">Czas wysyłki</div>
                    <span>{{ backup.product.deliveryTime }}</span>
                </div>
                <div class="backup__content__id__section">
                    <div class="backup__content__id__section__product">Kategoria</div>
                    <span>{{ backup.product.category }}</span>
                </div>
                <div class="backup__content__id__section">
                    <div class="backup__content__id__section__product">Podkategoria</div>
                    <span>{{ backup.product.subcategory }}</span>
                </div>
                <div class="backup__content__id__section">
                    <div class="backup__content__id__section__product">Dostępna Ilość</div>
                    <span>{{ backup.product.quantity }}</span>
                </div>
                <div class="backup__content__id__section">
                    <div class="backup__content__id__section__product">Klasa Towaru</div>
                    <span>{{ backup.product.qualityClass }}</span>
                </div>
                <div class="backup__content__id__section">
                    <div class="backup__content__id__section__product">Cena Podstawowa</div>
                    <span>{{ backup.product.basePrice }} PLN</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // Imports
    import {
    db
    } from '../configs/firebase'

    export default {
        data() {
        return {
            backup: {
            },
            loaded: false
        }
        },
        methods: {
            async getBackup()
            {
                try {
                    const result = await db.collection("Backups").doc('Products').collection('removedProducts').doc(this.$route.params.id).get();
                    this.backup = result.data();
                    this.loaded = true;
                    console.log(this.backup);
                }
                catch (error)
                {
                    console.log(error);
                }
            }
        },
        created()
        {
            this.getBackup();
        },
        mounted()
        {
            window.scrollTo(0,0);
        },
    }
</script>
<style lang="scss" scoped>
    @import '../styles/variables';

    .backup
    {
        width: 100vw;
        user-select: none;

        &__logo
        {
            display: flex;
            align-items: center;
            justify-self: center;
            width: 100%;
            height: 2.441rem;
            padding-left: 1rem;
            border-bottom: 1rem;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

            a
            {
                display: flex;
                align-items: center;
            }
            img
            {
                max-width: 
                7.451rem;
            }
        }
        
        &__content
        {
            width: 95%;
            margin-top: 1rem;   
            padding-left: 1rem;

            &__id
            {
                font-size: 1rem;

                &__header
                {
                    font-size: 1.25rem;
                    
                }

                &__section
                {
                    margin-bottom: 1rem;

                    &__productColumn
                    {
                        display: flex;
                        flex-direction: column;

                        &__base
                        {
                            margin-top: 1rem;
                            display: flex;
                            flex-direction: column;

                            &__nameRow
                            {
                                display: flex;
                                align-items: center;

                                &__indx
                                {
                                    font-size: 0.8rem;
                                    margin-right: 1rem;
                                }

                                &__name
                                {
                                    font-size: 0.8rem;
                                }
                                
                                span
                                {
                                    font-size: 0.8rem;
                                    color: $gray;
                                    margin-right: 1rem;
                                }

                                a
                                {
                                    color: $red;
                                    text-decoration: underline;
                                }

                                a:hover
                                {
                                    color: $gray;
                                }

                                img {
                                    max-width: 45px;
                                    max-height: 45px;
                                    margin-right: 1rem;
                                }
                            }

                            &__addons
                            {
                                &__addon
                                {
                                    font-size: 0.8rem;
                                    color: $gray;
                                    margin-left: 1rem;
                                    margin-top: 0.512rem;

                                    span
                                    {
                                        font-weight: 500;
                                        color: $black;
                                    }
                                }
                            }
                        }
                    }

                    &__name
                    {
                        font-size: 0.8rem;
                        color: $gray;
                    }

                    &__val
                    {
                        display: flex;
                        align-items: center;
                        font-weight: 500;

                        &--small
                        {
                            font-size: 0.64rem;
                            color: $gray;
                        }

                        &--notBold
                        {
                            color: $gray;
                            font-weight: 400;
                        }
                    }

                    &__snippet
                    {
                        display: flex;
                        align-items: center;
                        color: $gray;
                        font-size: 0.8rem;
                        border-bottom: 1px solid $border;
                        padding-bottom: 0.41rem;

                        i
                        {
                            padding-right: 0.512rem;
                        }

                        span
                        {
                            padding-right: 0.512rem;
                        }
                    }

                    &__product
                    {
                        display: flex;
                        align-items: center;
                        color: $gray;
                        font-size: 0.8rem;
                        border-bottom: 1px solid $border;
                        margin-bottom: 0.5rem;
                        i
                        {
                            padding-right: 0.512rem;
                        }

                        span
                        {
                            padding-right: 0.512rem;
                        }
                    }

                }
            }
        }
    }


    @media (max-width: 700px)
    {
        .backup__content__id__section__snippet
        {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
</style>